<template>
  <div class="container" v-if="isWedgeAdded">
    <div class="wedge-fasteners__table">
      <div class="wedge-fasteners__table-head">
        <div class="title">
          {{ $t('message.wedge.fasteners') }}
        </div>
      </div>
      <draggable
        v-model="wedgeFasteners"
        group="layers"
        @start="drag = true"
        @end="drag = false"
        handle=".handle"
        class="wedge-fasteners__table-body"
      >
        <div v-for="(item, i) in wedgeFasteners" :key="`fastener-${i}`" class="item">
          <div class="btn-handle handle"></div>
          <div class="item__number">{{ i + 1 }}</div>
          <div class="item__name" @click="updateFastener(i)">
            <div class="text">{{ findFastenerName(item.id) }}</div>
            <div class="icon"><img src="@/assets/img/icons/filter--select.svg" alt="" /></div>
          </div>
          <div class="item__count">
            <div class="item__count-input">
              <Wedge-fastener-count :count="item.count" :fastenerIndex="i" />
              <span class="units">{{ $t('message.units.pc') }}.</span>
            </div>
          </div>
          <div class="item__menu">
            <CircleMenu
              @clickedFromCircleMenu="circleMenuAction($event, i)"
              :setUp="getButtonsSetup(wedgeFasteners.length, i, item)"
            >
              <template #icon>
                <img src="@/assets/img/menu.svg" alt="" />
              </template>
            </CircleMenu>
          </div>
        </div>
      </draggable>
      <Wedge-add placement="fasteners" @clickedAdd="choseFastener" />
    </div>
    <Modal
      :isModalOpen="isModalOpen"
      :isNoRadius="true"
      :isCloseInHeader="true"
      :isShowClose="true"
      @close="close"
    >
      <template #body>
        <FastenersProductsView
          :path="[`${$t('message.wedge.fasteners')}`]"
          placement="fasteners"
          :response="fastenersContent"
          :fastenerIndex="fastenerIndex"
          :action="action"
          @close="close"
        />
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { getButtonsSetup } from '@/utils/customFunctions'
import { cloneDeep } from 'lodash'
import draggable from 'vuedraggable'
import CircleMenu from '@/components/elements/Dom/CircleMenu'
import WedgeAdd from '@/components/elements/Dom/Wedge-add'
import Modal from '@/components/elements/Modals/Modal'
import FastenersProductsView from '@/components/smart/layersProducts/ProductSelection'
import WedgeFastenerCount from '@/components/elements/Dom/WedgeFastenerCount'
export default {
  data: () => ({
    isModalOpen: false,
    fastenerIndex: 0,
    action: ''
  }),
  components: {
    draggable,
    CircleMenu,
    WedgeAdd,
    Modal,
    FastenersProductsView,
    WedgeFastenerCount
  },
  methods: {
    ...mapMutations({
      UPDATE_WEDGE_FASTENERS_ORDER: 'UPDATE_WEDGE_FASTENERS_ORDER'
    }),
    getButtonsSetup(itemsLength, index, item) {
      return getButtonsSetup(itemsLength, index, item, 'wedge')
    },
    circleMenuAction(action, currentIndex) {
      if (action === 'delete') {
        const [...newWedgeFasteners] = this.wedgeFasteners
        newWedgeFasteners.splice(currentIndex, 1)
        this.wedgeFasteners = Object.assign([], newWedgeFasteners)
      }
      if (action === 'up') {
        const arr = cloneDeep(this.wedgeFasteners)
        ;[arr[currentIndex], arr[currentIndex - 1]] = [arr[currentIndex - 1], arr[currentIndex]]
        this.UPDATE_WEDGE_FASTENERS_ORDER({
          sectorIndex: this.sectorIndex,
          value: arr
        })
      }
      if (action === 'down') {
        const arr = cloneDeep(this.wedgeFasteners)
        ;[arr[currentIndex], arr[currentIndex + 1]] = [arr[currentIndex + 1], arr[currentIndex]]
        this.UPDATE_WEDGE_FASTENERS_ORDER({
          sectorIndex: this.sectorIndex,
          value: arr
        })
      }
    },
    choseFastener() {
      this.action = 'add'
      this.isModalOpen = !this.isModalOpen
    },
    close() {
      this.isModalOpen = false
    },
    findFastenerName(id) {
      const fastener = this.slopeFasteners.find(p => p.id === id)
      return fastener ? fastener.name : this.$i18n.t('message.wedge.notFound')
    },
    updateFastener(fastenerIndex) {
      this.fastenerIndex = fastenerIndex
      this.action = 'replace'
      this.isModalOpen = !this.isModalOpen
    }
  },
  computed: {
    ...mapState({
      slopeFasteners: state => state.slopeFasteners,
      slopeFastenersCategories: state => state.slopeFastenersCategories
    }),
    isWedgeAdded() {
      return Object.prototype.hasOwnProperty.call(this.sectorFromState, 'wedge')
    },
    fastenersContent() {
      return { categories: this.slopeFastenersCategories, products: this.slopeFasteners }
    },
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorIndex)
    },
    wedgeFasteners: {
      get() {
        return this.sectorFromState.wedge.fasteners
      },
      set(value) {
        this.UPDATE_WEDGE_FASTENERS_ORDER({
          sectorIndex: this.sectorIndex,
          value: value
        })
      }
    }
  }
}
</script>

<style scoped lang="sass">
.wedge
  &-fasteners
    &__table
      +media((width: (320: 100%, 650: 92%, 960: 100%)))
      margin: 0 auto
      +media((padding-top: (320: rem(8), 960: rem(16))))
      &-head
        display: grid
        grid-template-columns: 1fr 46fr 1fr
        background: #ffffff
        border-radius: rem(4) rem(4) 0 0
        padding: rem(16) 0
        box-shadow: inset 0 rem(-1) 0 #ECECEC
        .title
          @extend .fs-18
          font-weight: bold
          grid-column-start: 2
      &-body
        display: grid
        grid-template-columns: 1fr
        .item
          display: grid
          +media((grid-template-columns: (320: rem(24) rem(24) 25fr 1fr 10fr 1fr rem(32), 400: rem(32) rem(32) 25fr 2fr 9fr 1fr rem(32), 960: 1fr 2fr 1fr 32fr 1fr 5fr 4fr 2fr)))
          background: #fff
          box-shadow: inset 0 rem(-1) 0 #ECECEC
          transition: .5s
          +media((min-height: (320: rem(56), 960: unset)))
          @media (any-hover: hover)
            &:hover
              box-shadow: $default-box-shadow
              z-index: 1
              .item__count-input
                border-color: #D6D6D6
          .btn-handle
            height: 100%
            cursor: move
            width: 100%
            // width: rem(40)
            background-image: url(~@/assets/img/icons/drag.png)
            background-repeat: no-repeat
            background-position: center
            box-shadow: inset rem(-1) 0 0 #ECECEC
          &__number
            @extend .fs-12
            font-weight: bold
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            color: #D6D6D6
          &__name
            cursor: pointer
            +media((grid-column-start: (320: 3, 960: 4)))
            box-shadow: inset rem(-1) 0 0 #ECECEC
            display: flex
            flex-direction: row
            align-items: center
            @extend .fs-14
            +media((font-size: (320: rem(12), 400: rem(14))))
            font-weight: bold
            justify-content: space-between
            .icon
              +media((width: (320: rem(24), 400: rem(32), 960: rem(40))))
              display: flex
              flex-direction: row
              align-items: center
              justify-content: center
          &__count
            +media((grid-column-start: (320: 5, 960: 6)))
            display: flex
            flex-direction: row
            align-items: center
            padding: rem(8) 0
            &-input
              display: flex
              flex-direction: row
              +media((width: (320: 100%, 960: rem(100))))
              justify-content: space-between
              height: rem(32)
              align-items: center
              border: rem(1) solid transparent
              box-sizing: border-box
              border-radius: rem(4)
              transition: .5s
              +media((border-color: (320: #D6D6D6, 960: transparent)))
              input
                width: 100%
                +media((padding: (320: 0 rem(6), 400: 0 rem(8), 960: 0 rem(14))))
                @extend .clear-input
                @extend .fs-14
                font-weight: bold
                +media((font-size: (320: rem(12), 960: rem(14))))
              .units
                @extend .fs-12
                color: #999999
                font-weight: bold
                padding-right: rem(7)
          &__menu
            grid-column-start: 8
            position: relative
</style>
